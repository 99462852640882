import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import { Switch } from "@material-ui/core";
import { Link, navigate, PageProps } from "gatsby";
import Swal from "sweetalert2";

import CancelAppointment from "~/components/Appointments/Cancel/CancelAppointment";
import CartMaker from "~/components/Appointments/CartMaker/CartMaker";
import CartMakerV2 from "~/components/Appointments/CartMaker/CartMakerV2";
import PrivateRoute from "~/components/Authentication/PrivateRoute";
import Button from "~/components/Buttons/Button";
import LoadingError from "~/components/Loaders/LoadingError";
import Bold from "~/components/Typography/Bold";
import useAddressValue from "~/hooks/useAddressValue";
import appointmentService from "~/utils/api/v1/appointmentService";
import { DetailedAppointment } from "~/utils/interfaces/Appointment";
import { AppointmentPatient } from "~/utils/interfaces/AppointmentPatient";
import { baseUser } from "~/utils/interfaces/User";

interface Event {
  appointment: string;
  created_at: string;
  id: string;
  owner: string | null;
  tag: string;
}

interface AppointmentDetailProps {
  id: string;
  location: PageProps["location"];
}

const AppointmentDetail = ({ id, location }: AppointmentDetailProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>({});
  const [patients, setPatients] = useState<Array<AppointmentPatient>>([]);
  const [forSdk, setForSdk] = useState<boolean>(false);
  const [events, setEvents] = useState<Array<Event>>([]);
  const [releasingBlock, setReleasingBlock] = useState(false);
  // @ts-expect-error
  const [appointmentData, setAppointmentData] = useState<DetailedAppointment>({
    patient: baseUser,
    patients: [],
    timeline_events: [],
    timeline_events_tags: [],
    services: [],
  });
  const [paramsFromUrl, setParamsFromUrl] = useState<any>({});

  const cancelComponentRef = useRef<any>(null);
  const { clear: clearAddress } = useAddressValue();

  const fetchData = async (): Promise<void> => {
    setLoading(true);
    try {
      const appointment = await appointmentService.fetchDashboardAppointment(id);
      updateAppointmentData(appointment.data.data);
      setEvents(appointment.data.data.timeline_events);
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  };

  const releaseBlock = async () => {
    setReleasingBlock(true);
    try {
      Swal.fire({
        icon: "warning",
        title: "¿Estás seguro de que quieres liberar este horario?",
        confirmButtonText: "Si, liberar",
        showCancelButton: true,
        cancelButtonText: "No",
      }).then(async (res) => {
        if (res.value) {
          const releseBlockRes = await appointmentService.releaseBlock(id);
          if (releseBlockRes) {
            Swal.fire({
              icon: "success",
              title: "Horario liberado para el HT",
            });
            fetchData();
          }
        }
      });

      setReleasingBlock(false);
    } catch (err) {
      setReleasingBlock(false);
    }
  };

  const fetchPatients = async (): Promise<void> => {
    try {
      const patientsData = await appointmentService.getPatients(id);
      setPatients(patientsData.data.data);
    } catch (err) {
      setError(err);
    }
  };

  const updateAppointmentData = (appointment: DetailedAppointment) => {
    const timeline_events_tags = appointment.timeline_events.map((val) => val.tag);
    appointment.timeline_events_tags = timeline_events_tags;
    setAppointmentData(appointment);
  };

  useEffect((): void => {
    // TODO: Each of these should be handled in its own place so
    // instead of the whole page loading,
    // one of the squares will be loading
    clearAddress();
    const params = new URLSearchParams(location.search);
    const switchModeParam = params.get("mode");
    if (switchModeParam === "sdk") setForSdk(true);
    fetchData();
    fetchPatients();
  }, []);

  useLayoutEffect(() => {
    const params = new URLSearchParams(location.search);
    const switchModeParam = params.get("mode");
    const medicalOrderId = params.get("order");
    setParamsFromUrl({ sdkMode: switchModeParam === "sdk", medicalOrderId });
  }, []);

  const displayableEndHour = () => {
    if (appointmentData.local_work_period_max_lateness) {
      return appointmentData.local_work_period_max_lateness.match(/(\d{2}:\d{2})/)?.[0];
    }
  };

  return (
    <PrivateRoute>
      <LoadingError
        loading={loading}
        error={error}
      />
      <CancelAppointment
        id={appointmentData.id}
        ref={cancelComponentRef}
        updateAppointmentData={updateAppointmentData}
        setError={setError}
      />
      {!loading && (
        <div className="flex flex-col max-w-6xl bg-white p-16 border rounded-lg items-center gap-y-4 w-full">
          <h1>Cita a través de orden médica</h1>
          <div className="bg-[#f5f5f5] rounded-lg p-4 w-full space-y-2">
            {appointmentData.patients.length > 0 && (
              <Button
                onClick={async () => navigate(`/appointment/${id}`)}
                textClassName="text-xs text-[#039Be5]"
                text="IR AL DETALLE DE ESTA CITA"
                className="pl-0"
              />
            )}
            <p className="mb-0">
              <Bold>Paciente principal: </Bold>
              {appointmentData.patient_full_name || "Sin pacientes asignados"}
            </p>
            <p className="mb-0">
              <Bold>Estado: </Bold>
              {appointmentData.status === "scheduled" ? "Sin confirmar" : "confirmada"}
            </p>
            <p className="mb-0">
              <Bold>HT: </Bold>
              {appointmentData?.nurse?.user?.full_name || "Sin HT asignado"}
            </p>
            <p className="mb-0">
              <Bold>Hora tentativa: </Bold>
              {appointmentData.displayable_begin_date} - {displayableEndHour()}
            </p>
            <p className="mb-0">
              <Bold>¿Hora tomada?: </Bold>
              {appointmentData.block_taken ? "Sí" : "No"}
            </p>
            <p>
              <Bold>Salessource: </Bold>
              {appointmentData.sales_source}
            </p>
            <Button
              disabled={!appointmentData.block_taken || releasingBlock}
              onClick={releaseBlock}
              className="blue !px-5 !py-2 !rounded mt-2"
              text=" Liberar hora"
            />
          </div>
          <div className="bg-[#f5f5f5] rounded-lg p-4 w-full">
            {appointmentData.sales_source != "nuevamasvida" && (
              <>
                <div className="flex justify-center items-center">
                  Marketplace
                  <Switch
                    color="primary"
                    checked={forSdk}
                    onChange={() => setForSdk((oldValue) => !oldValue)}
                  />
                  SDK
                </div>
                {!forSdk && (
                  <CartMakerV2
                    appointmentId={id}
                    className="mx-auto max-w-4xl"
                  />
                )}
                {forSdk && (
                  <CartMaker
                    forSdk
                    appointmentId={id}
                    salesSource={appointmentData.sales_source}
                  />
                )}
              </>
            )}
            {appointmentData.sales_source === "nuevamasvida" && (
              <div style={{ textAlign: "center" }}>
                <span style={{ fontSize: "22px" }}>
                  Esta cita fue creada por flujo Nueva Masvida, para continuar debes <br />
                  <Link to="/offline-appointment/assisted-schedule/">Ir al agendamiento asistido</Link>
                </span>
              </div>
            )}
          </div>
        </div>
      )}
    </PrivateRoute>
  );
};

export default AppointmentDetail;
